import React from "react";

import keyVisual from "../../assets/img/keyvisual.jpg";
import keyVisualSKNuernberg from "../../assets/img/keyvisualSKNuernberg.jpg";
import keyVisualDusseldorf from "../../assets/img/keyvisual-dusseldorf.jpg";
import keyVisualMarkgraeflerland from "../../assets/img/keyvisual-markgraeflerland.jpg";
import keyVisualBamberg from "../../assets/img/keyvisual-bamberg.jpg";
import keyVisualBodensee from "../../assets/img/keyvisual-bodensee.jpg";
import keyVisualRheinMaas from "../../assets/img/keyvisual-rheinmaas.jpg";
import keyVisualLengerich from "../../assets/img/keyvisual-lengerich.jpg";
import keyVisualWuppertal from "../../assets/img/keyvisual-wuppertal.jpg";
import keyVisualHildesheim from "../../assets/img/keyvisual-hildesheim.jpg";
import keyVisualMainfrankenWuerzburg from "../../assets/img/keyvisual-mainfranken-wuerzburg.jpg";
import keyVisualErdingDorfen from "../../assets/img/keyvisual-erding-dorfen.jpg";
import keyVisualMoenchengladbach from "../../assets/img/keyvisual-moenchengladbach.jpg";
import keyVisualIngolstadtEichstaett from "../../assets/img/keyvisual-ingolstadt-eichstaett.jpg";
import keyVisualZollernalb from "../../assets/img/keyvisual-zollernalb.jpg";
import keyVisualSiegen from "../../assets/img/keyvisual-siegen.jpg";
import keyVisualCoburgLichtenfels from "../../assets/img/keyvisual-coburg-lichtenfels.jpg";
import keyVisualSchwaebischHallCrailsheim from "../../assets/img/keyvisual-schwaebisch-hall-crailsheim.jpg";
import keyVisualBadenBadenGagenau from "../../assets/img/keyvisual-baden-baden-gaggenau.jpg";
import keyVisualKarlsruhe from "../../assets/img/keyvisual-karlsruhe.jpg";
import keyVisualNeuss from "../../assets/img/keyvisual-neuss.jpg";
import keyVisualKSKDuesseldorf from "../../assets/img/keyvisual-ksk-dusseldorf.jpg";
import keyVisualJena from "../../assets/img/keyvisual-jena.jpg";
import keyVisualHegauBodensee from "../../assets/img/keyvisual-hegau-bodensee.jpeg";
import keyVisualTraunsteinTrostberg from "../../assets/img/keyvisual-traunstein-trostberg.jpg";
//import keyVisualSchwarzwaldBaar from "../../assets/img/keyvisual-schwarzwald-baar.jpg"; --> removed because temporary no longer needed

import SVGDekaLogo from "../svg/DekaLogo";
import SVGLogoSkNuernberg from "../svg/LogoSkNuernberg";
import SVGLogoDusseldorf from "../svg/LogoDusseldorf";
import SVGLogoMarkgraeflerland from "../svg/LogoMarkgraeflerland";
import SVGLogoBamberg from "../svg/LogoBamberg";
import SVGLogoBodensee from "../svg/LogoBodensee";
import SVGLogoRheinmaas from "../svg/LogoRheinMaas";
import SVGLogoLengerich from "../svg/LogoLengerich";
import SVGLogoWuppertal from "../svg/LogoWuppertal";
import SVGLogoHildesheim from "../svg/LogoHildesheim";
import SVGLogoMainfrankenWuerzburg from "../svg/LogoMainfrankenWuerzburg";
import SVGLogoErdingDorfen from "../svg/LogoErdingDorfen";
import SVGLogoMoenchengladbach from "../svg/LogoMoenchengladbach";
import SVGLogoIngolstadtEichstaett from "../svg/LogoIngolstadtEichstaett";
import SVGLogoZollernalb from "../svg/LogoZollernalb";
import SVGLogoSiegen from "../svg/LogoSiegen";
import SVGLogoCoburgLichtenfels from "../svg/LogoCoburgLichtenfels";
import SVGLogoSchwaebischHallCrailsheim from "../svg/LogoSchwaebischHallCrailsheim";
//import SVGNordOstseeSparkasse from "../svg/LogoNordOstseeSparkasse"; --> removed because temporary no longer needed
import SVGLogoBadenBadenGaggenau from "../svg/LogoBadenBadenGaggenau";
import SVGLogoKarlsruhe from "../svg/LogoKarlsruhe";
import SVGLogoNeuss from "../svg/LogoNeuss";
import SVGLogoKskDuesseldorf from "../svg/LogoKskDuesseldorf";
import SVGLogoJena from "../svg/LogoJena";
import SVGLogoHegauBodensee from "../svg/LogoHegauBodensee";
//import SVGLogoSchwarzwaldBaar from "../svg/LogoSchwarzwaldBaar"; --> removed because temporary no longer needed
import SVGLogoOberhessen from "../svg/LogoOberhessen";
import SVGLogoTraunsteinTrostberg from "../svg/LogoTaunsteinTrostberg";

import { BrandingTypes } from "../../store/data/types";

const Brandings: Function = (brand: string): any => {
    const defaultConfig: BrandingTypes = {
        brand: "default",
        productName: "Deka-Vermögensverwaltung Premium",
        backgroundImage: keyVisual,
        additionalClass: "",
        implementationOptions: [
            "einzeltitel",
            "fondsbausteine",
            "einzeltitelUndAusschuettungsfokus"
        ],
        minInvestmentVolume: {
            einzeltitel: 250000,
            fondsbausteine: 100000,
            einzeltitelUndAusschuettungsfokus: 100000
        },
        logo: (
            <SVGDekaLogo
                viewBox={"0 0 341 65"}
                className={"header__content-logo"}
            />
        ),
        hasFinanzGruppeLogo: true,
        hasExclusiveExtraPdf: false
    };

    const brandsList: any = {
        76050101: {
            brand: "Nuernberg",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualSKNuernberg,
            additionalClass: defaultConfig.additionalClass,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoSkNuernberg />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        94059421: {
            brand: "Nuernberg (second Vermittler)",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualSKNuernberg,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoSkNuernberg />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        30050110: {
            brand: "Dusseldorf",
            productName: "InvestDus – die Premium Vermögensverwaltung",
            backgroundImage: keyVisualDusseldorf,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: (
                <SVGLogoDusseldorf
                    viewBox={"0 0 133.2 42.1"}
                    className={"logo-dusseldorf"}
                />
            ),
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        68351865: {
            brand: "Markgräflerland",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualMarkgraeflerland,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoMarkgraeflerland viewBox={"0 0 70.89 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        77050000: {
            brand: "Bamberg",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualBamberg,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoBamberg viewBox={"0 0 79.47 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        69050001: {
            brand: "Bodensee",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualBodensee,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoBodensee viewBox={"0 0 90 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        32450000: {
            brand: "Rhein-Maas",
            productName: "Sparkasse Rhein-Maas Vermögensverwaltung Premium",
            backgroundImage: keyVisualRheinMaas,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoRheinmaas viewBox={"0 0 95.62 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        40154476: {
            brand: "Lengerich",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualLengerich,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoLengerich viewBox={"0 0 37 12.55"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        33050000: {
            brand: "Wuppertal",
            productName: "Wuppertal Premium Invest",
            backgroundImage: keyVisualWuppertal,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: {
                einzeltitel: defaultConfig.minInvestmentVolume.einzeltitel,
                fondsbausteine: 75000,
                einzeltitelUndAusschuettungsfokus: 75000
            },
            logo: <SVGLogoWuppertal viewBox={"0 0 109.45 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: true
        },
        25950130: {
            brand: "Hildesheim Goslar Peine",
            productName: "Vermögensverwaltung Premium Sparkasse HGP",
            backgroundImage: keyVisualHildesheim,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoHildesheim viewBox={"0 0 175.59 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        79050000: {
            brand: "Mainfranken Würzburg",
            productName: "Mainfranken Premium Invest",
            backgroundImage: keyVisualMainfrankenWuerzburg,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoMainfrankenWuerzburg viewBox={"0 0 151.38 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        70051995: {
            brand: "Erding – Dorfen",
            productName: "SPKED Vermögensverwaltung",
            backgroundImage: keyVisualErdingDorfen,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoErdingDorfen viewBox={"0 0 105.17 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        31050000: {
            brand: "Stadtsparkasse Mönchengladbach",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualMoenchengladbach,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoMoenchengladbach viewBox={"0 0 126.33 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        72150000: {
            brand: "Sparkasse Ingolstadt Eichstätt",
            productName: "Vermögensverwaltung Sparkasse Ingolstadt Eichstätt",
            backgroundImage: keyVisualIngolstadtEichstaett,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoIngolstadtEichstaett viewBox={"0 0 134.39 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        65351260: {
            brand: "Sparkasse Zollernalb",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualZollernalb,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoZollernalb viewBox={"0 0 85.41 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        46050001: {
            brand: "Sparkasse Siegen",
            productName: "S-Premium Invest",
            backgroundImage: keyVisualSiegen,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoSiegen viewBox={"0 0 79.47 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        78350000: {
            brand: "Sparkasse Coburg-Lichtenfels",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualCoburgLichtenfels,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoCoburgLichtenfels viewBox={"0 0 135.81 37"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        62250030: {
            brand: "Sparkasse Schwäbisch Hall Crailsheim",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualSchwaebischHallCrailsheim,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: (
                <SVGLogoSchwaebischHallCrailsheim
                    viewBox={"0 0 83.51 37"}
                    className={"logo-schwaebisch-hall-crailsheim"}
                />
            ),
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        21750000: {
            brand: "Nord-Ostsee Sparkasse",
            productName: defaultConfig.productName,
            backgroundImage: keyVisual,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: (
                <SVGDekaLogo
                    viewBox={"0 0 341 65"}
                    className={"header__content-logo"}
                />
            ),
            hasFinanzGruppeLogo: true,
            hasExclusiveExtraPdf: false
        },
        66250030: {
            brand: "Sparkasse Baden-Baden Gaggenau",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualBadenBadenGagenau,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: (
                <SVGLogoBadenBadenGaggenau
                    viewBox={"0 0 99.83 37"}
                    className={"logo-baden-baden-gaggenau"}
                />
            ),
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        66050101: {
            brand: "Sparkasse Karlsruhe",
            productName: "Karlsruhe Vermögensverwaltung Premium",
            backgroundImage: keyVisualKarlsruhe,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoKarlsruhe viewBox={"0 0 557.32 240.52"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        30550000: {
            brand: "Sparkasse Neuss",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualNeuss,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoNeuss viewBox={"0 0 151.2 65.5"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        30150200: {
            brand: "Kreissparkasse Düsseldorf",
            productName:
                "Vermögensverwaltung Premium Kreissparkasse Düsseldorf",
            backgroundImage: keyVisualKSKDuesseldorf,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoKskDuesseldorf viewBox={"0 0 532.3 170.1"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        83053030: {
            brand: "Sparkasse Jena-Saale-Holzland",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualJena,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoJena viewBox={"0 0 263.6 65.4"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        69250035: {
            brand: "Sparkasse Hegau-Bodensee",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualHegauBodensee,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoHegauBodensee viewBox={"0 0 224.6 70"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        69450065: {
            brand: "Sparkasse Schwarzwald-Baar",
            productName: defaultConfig.productName,
            backgroundImage: keyVisual,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: (
                <SVGDekaLogo
                    viewBox={"0 0 341 65"}
                    className={"header__content-logo"}
                />
            ),
            hasFinanzGruppeLogo: true,
            hasExclusiveExtraPdf: false
        },
        51850079: {
            brand: "Sparkasse Oberhessen",
            productName: defaultConfig.productName,
            backgroundImage: keyVisual,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoOberhessen viewBox={"0 0 317.3 122"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        },
        71052050: {
            brand: "Kreissparkasse Traunstein-Trostberg",
            productName: defaultConfig.productName,
            backgroundImage: keyVisualTraunsteinTrostberg,
            implementationOptions: defaultConfig.implementationOptions,
            minInvestmentVolume: defaultConfig.minInvestmentVolume,
            logo: <SVGLogoTraunsteinTrostberg viewBox={"0 0 265.9 70.4"} />,
            hasFinanzGruppeLogo: false,
            hasExclusiveExtraPdf: false
        }
    };

    return brandsList[brand] || defaultConfig;
};

export default Brandings;
