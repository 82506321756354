import React, { BaseSyntheticEvent, FormEvent } from "react";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import InputText from "../../components/InputText/InputText";
import {
    importForm,
    updateCurrentPage,
    updateForm
} from "../../store/data/actions";
import InputTextCheckbox from "../../components/InputTextCheckbox/InputTextCheckbox";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";
import "../../scss/config/Dropdown.scss";
import SVGDirection from "../../components/svg/Direction";
import { connect } from "react-redux";
import { Form } from "../../store/data/types";
import BackToSummary from "../../components/BackToSummary/BackToSummary";
import { StoreManager } from "../../store";
import path from "../../components/utils/GetPageNumberByPath";
import { Grid, Row, Col } from "react-flexbox-grid";
import InfoText from "../../components/InfoText/InfoText";
import LinkStandard from "../../components/LinkStandard/LinkStandard";
import checkLawPerson from "../../components/utils/CheckLawPerson";

interface ComponentProps {
    currentPage: number;
    updateForm: Function;
    updateCurrentPage: Function;
    forms: Form[];
    path: string;
    importForm: Function;
}

interface ComponentState {
    [index: string]: string | boolean;
    salutation: string;
    title: string;
    firstname: string;
    surname: string;
    salutation2: string;
    title2: string;
    firstname2: string;
    surname2: string;
    company: string;
    address: string;
    addressNumber: string;
    addressZip: string;
    addressCity: string;
    isLegalPerson: boolean;
    isExemptFromTax: boolean;
    isDisabled: boolean;
    isCouple: boolean;
    offerDate: string;
    offerDateErrorMsg: string;
    isDateError: boolean;
    showDateAbsoluteError: boolean;
}

class Page01 extends React.Component<ComponentProps, ComponentState> {
    state: ComponentState = {
        salutation: "",
        title: "",
        firstname: "",
        surname: "",
        salutation2: "",
        title2: "",
        firstname2: "",
        surname2: "",
        company: "",
        address: "",
        addressNumber: "",
        addressZip: "",
        addressCity: "",
        isLegalPerson: false,
        isExemptFromTax: false,
        isDisabled: true,
        isCouple: false,
        offerDate: "",
        offerDateErrorMsg: "",
        isDateError: false,
        showDateAbsoluteError: false
    };

    resetState = { ...this.state };

    sendForm = () => {
        this.props.updateForm({
            pageNumber: path(this.props.path),
            data: this.state
        });
    };

    componentDidMount() {
        const find: Form | undefined = StoreManager.getState().forms.find(
            item => item.pageNumber === path(this.props.path)
        );
        if (find) {
            this.setState(find.data as ComponentState);
        } else {
            this.sendForm();
        }
    }

    componentDidUpdate() {
        if (StoreManager.getState().forms.length === 0) {
            this.setState({ ...this.resetState }, () => this.sendForm());
        }
    }

    handleFormSubmission = (e: FormEvent): void => {
        e.preventDefault();
        this.props.updateCurrentPage(this.props.currentPage + 1);
    };

    handleInputChange = (e: BaseSyntheticEvent): void => {
        this.setState(
            { [e.target.name]: e.target.value } as ComponentState,
            () => {
                this.showHideOfferDateError();
                this.setState({ isDisabled: this.checkDisableButton() }, () =>
                    this.sendForm()
                );
            }
        );
    };

    showHideOfferDateError = (): void => {
        if (this.state.offerDate.length === 10 && !this.isOfferDateValid()) {
            this.setState({ isDateError: true, showDateAbsoluteError: true });
        } else {
            this.setState({
                isDateError: false,
                showDateAbsoluteError: false,
                offerDateErrorMsg: ""
            });
        }
    };

    isOfferDateValid = (): boolean => {
        const userEntered = this.state.offerDate;
        if (userEntered.length !== 10) {
            return false;
        }

        const d: Date = new Date(
            this.state.offerDate.replace(/(\d{2}).(\d{2}).(\d{4})/, "$2/$1/$3")
        );
        if (!(d instanceof Date) || isNaN(d.getTime())) {
            this.setState({
                offerDateErrorMsg: "Bitte geben Sie ein gültiges Datum ein."
            });
            return false;
        }

        /* compare if input date is bigger than today */
        /* convert input value into german timezone */
        const now = new Date();
        const today = new Date(
            Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
        );

        if (d!.getTime() > today.getTime()) {
            this.setState({
                offerDateErrorMsg:
                    "Bitte geben Sie ein Datum ein, das nicht in der Zukunft liegt."
            });
            return false;
        }
        return true;
    };

    handleDropdownChange = (o: Option, property: string) => {
        if (o.value === this.options[0]) {
            this.setState(
                {
                    [property]: ""
                },
                () =>
                    this.setState(
                        { isDisabled: this.checkDisableButton() },
                        () => this.sendForm()
                    )
            );
        } else {
            this.setState(
                {
                    [property]: String(o.label)
                },
                () => {
                    this.setState(
                        { isDisabled: this.checkDisableButton() },
                        () => this.sendForm()
                    );
                }
            );
        }
    };

    handleCheckBox = (e: BaseSyntheticEvent): void => {
        this.setState(
            { [e.target.name]: e.target.checked } as ComponentState,
            () => {
                this.sendForm();
                this.verifyCompensationValue();
            }
        );
    };

    checkDisableButton = () => {
        // this code could  be simplified, but I kept
        // this way for the sake of readability, because
        // thewre are many conditions
        const {
            address,
            addressCity,
            addressZip,
            addressNumber,
            firstname,
            surname,
            firstname2,
            surname2,
            company,
            salutation,
            salutation2
        } = this.state;
        let isAddressRequired = false;
        let isNameRequired = true;
        let isName2Required = false;

        if (address || addressCity || addressZip || addressNumber) {
            isAddressRequired = true;
        }
        if (
            address &&
            addressCity &&
            addressZip.length === 5 &&
            addressNumber
        ) {
            isAddressRequired = false;
        }

        if (this.state.isCouple) {
            isName2Required = true;
        }

        if (!this.isOfferDateValid()) {
            return true;
        }

        if (firstname && surname && salutation) {
            isNameRequired = false;
        }

        if (firstname2 && surname2 && salutation2) {
            isName2Required = false;
        }
        if (
            (!isAddressRequired &&
                !isNameRequired &&
                !isName2Required &&
                salutation) ||
            (company &&
                !isAddressRequired &&
                !isNameRequired &&
                !isName2Required)
        ) {
            return false;
        } else {
            return true;
        }
    };

    getHiddenClass = () => {
        return this.state.isCouple ? "row--optional-active" : "";
    };

    verifyCompensationValue = () => {
        const find = this.props.forms.find(
            item => item.pageNumber === path("verguetungsmodell")
        );

        // Change compensation value for law person
        if (find) {
            if (!find.data.hasValueChanged) {
                this.props.updateForm({
                    pageNumber: path("verguetungsmodell"),
                    data: checkLawPerson(this.state.isLegalPerson)
                });
            }
        }
    };

    handeLinkClick = () => {
        this.setState(
            {
                isCouple: !this.state.isCouple,
                salutation2: "",
                title2: "",
                firstname2: "",
                surname2: ""
            },
            () =>
                this.setState({ isDisabled: this.checkDisableButton() }, () =>
                    this.sendForm()
                )
        );
    };
    options = ["Anrede", "Frau", "Herr"];

    render() {
        return (
            <section className="parallax-layer">
                <Heading rank={2} text={"1.0 Persönliche Daten"} />

                <div className="box-model">
                    <Grid fluid>
                        <Row>
                            <Col xs={6}>
                                <div className="form-item-text">
                                    <label className="form-item-text__label form-item-text__label--empty"></label>
                                    <Dropdown
                                        options={this.options}
                                        placeholder={"Anrede"}
                                        value={this.state.salutation}
                                        onChange={(o: Option) =>
                                            this.handleDropdownChange(
                                                o,
                                                "salutation"
                                            )
                                        }
                                        arrowClosed={
                                            <SVGDirection
                                                className={"Dropdown-svg-arrow"}
                                                viewBox={"0 0 14 14"}
                                            />
                                        }
                                        arrowOpen={
                                            <SVGDirection
                                                className={"Dropdown-svg-arrow"}
                                                viewBox={"0 0 14 14"}
                                            />
                                        }
                                    />
                                    <label
                                        data-content={"Anrede"}
                                        className={
                                            "form-item-text__label--placeholder" +
                                            (this.state.salutation
                                                ? " active"
                                                : "")
                                        }
                                    >
                                        Anrede
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <InputText
                                    id={"title"}
                                    name={"title"}
                                    value={this.state.title}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Titel"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6}>
                                <InputText
                                    id={"firstname"}
                                    name={"firstname"}
                                    value={this.state.firstname}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Vorname"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputText
                                    id={"surname"}
                                    name={"surname"}
                                    value={this.state.surname}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Nachname"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row
                            className={
                                "row--optional row--bottom-0 " +
                                this.getHiddenClass()
                            }
                        >
                            <Col xs={6}>
                                <div className="form-item-text">
                                    <label className="form-item-text__label form-item-text__label--empty"></label>
                                    <Dropdown
                                        options={this.options}
                                        placeholder={"Anrede"}
                                        value={this.state.salutation2}
                                        onChange={(o: Option) =>
                                            this.handleDropdownChange(
                                                o,
                                                "salutation2"
                                            )
                                        }
                                        arrowClosed={
                                            <SVGDirection
                                                className={"Dropdown-svg-arrow"}
                                                viewBox={"0 0 14 14"}
                                            />
                                        }
                                        arrowOpen={
                                            <SVGDirection
                                                className={"Dropdown-svg-arrow"}
                                                viewBox={"0 0 14 14"}
                                            />
                                        }
                                    />
                                    <label
                                        data-content={"Anrede"}
                                        className={
                                            "form-item-text__label--placeholder" +
                                            (this.state.salutation2
                                                ? " active"
                                                : "")
                                        }
                                    >
                                        Anrede
                                    </label>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <InputText
                                    id={"title2"}
                                    name={"title2"}
                                    value={this.state.title2}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Titel"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row
                            className={
                                "row--optional row--bottom-0 " +
                                this.getHiddenClass()
                            }
                        >
                            <Col xs={6}>
                                <InputText
                                    id={"firstname2"}
                                    name={"firstname2"}
                                    value={this.state.firstname2}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Vorname"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                            <Col xs={6}>
                                <InputText
                                    id={"surname2"}
                                    name={"surname2"}
                                    value={this.state.surname2}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Nachname"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InfoText underForm={true}>
                                    <LinkStandard
                                        additionalClass={
                                            "link-standard--bold link-standard--dark link-standard--form-option"
                                        }
                                        icon={
                                            this.state.isCouple
                                                ? "minus"
                                                : "plus"
                                        }
                                        onClick={() => {
                                            this.handeLinkClick();
                                        }}
                                    >
                                        {!this.state.isCouple
                                            ? "Zweite Person hinzufügen"
                                            : "Zweite Person entfernen"}
                                    </LinkStandard>
                                </InfoText>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <InputText
                                    id={"company"}
                                    name={"company"}
                                    value={this.state.company}
                                    onChange={this.handleInputChange}
                                    placeHolder={
                                        "Unternehmen, Institutionen, etc."
                                    }
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={9}>
                                <InputText
                                    id={"address"}
                                    name={"address"}
                                    value={this.state.address}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Straße"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                            <Col xs={3}>
                                <InputText
                                    id={"addressNumber"}
                                    name={"addressNumber"}
                                    value={this.state.addressNumber}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Hausnummer"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <InputText
                                    id={"addressZip"}
                                    name={"addressZip"}
                                    isNumber={true}
                                    isZip={true}
                                    maxLength={5}
                                    value={this.state.addressZip}
                                    onChange={this.handleInputChange}
                                    placeHolder={"PLZ"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                            <Col xs={9}>
                                <InputText
                                    id={"addressCity"}
                                    name={"addressCity"}
                                    value={this.state.addressCity}
                                    onChange={this.handleInputChange}
                                    placeHolder={"Stadt"}
                                    showPlaceholderAsLabel={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <InputTextCheckbox
                                    name={"isLegalPerson"}
                                    checked={this.state.isLegalPerson}
                                    onChange={this.handleCheckBox}
                                >
                                    Juristische Person
                                </InputTextCheckbox>
                            </Col>
                            <Col xs={3}>
                                <InputTextCheckbox
                                    name={"isExemptFromTax"}
                                    checked={this.state.isExemptFromTax}
                                    onChange={this.handleCheckBox}
                                >
                                    Steuerbefreit
                                </InputTextCheckbox>
                            </Col>
                        </Row>
                        <Row className={"row"}>
                            <InfoText noMargins={false}>
                                <Col xs={12}>
                                    <div className="info-text__child--margin-down">
                                        Diese Vorschlag wird auf Basis des/der
                                        vorliegenden Kundenbefragungsbogen/s
                                        gem. § 64 Abs. 3 WpHG vom
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <InputText
                                        isInline={true}
                                        isInlineNoMargin={true}
                                        isDate={true}
                                        id={"offerDate"}
                                        name={"offerDate"}
                                        placeHolder={"TT.MM.JJJJ"}
                                        additionalClass={
                                            "form-item-text--offer-date"
                                        }
                                        value={this.state.offerDate}
                                        onChange={this.handleInputChange}
                                        isError={this.state.isDateError}
                                        isAbsoluteError={
                                            this.state.showDateAbsoluteError
                                        }
                                        errorMessage={
                                            this.state.offerDateErrorMsg
                                        }
                                    />
                                    erstellt.
                                </Col>
                            </InfoText>
                        </Row>
                    </Grid>
                </div>

                <Grid fluid className={"bottom-sticky"}>
                    <Row>
                        <Col xs={12} className={"align-right"}>
                            <BackToSummary />
                            <Button
                                styling={"primary"}
                                onClick={this.handleFormSubmission}
                                isDisabled={this.state.isDisabled}
                            >
                                Weiter
                            </Button>
                        </Col>
                    </Row>
                </Grid>
            </section>
        );
    }
}

const mapStaTeToProps = (props: ComponentProps) => {
    return { currentPage: props.currentPage, forms: props.forms };
};

export default connect(mapStaTeToProps, {
    updateCurrentPage,
    updateForm,
    importForm
})(Page01);
